import React, { Component, Fragment } from "react";
import { Form, Button, Input } from "antd";
import { observer } from "mobx-react";
import _size from "lodash/size";

import Select, { Option } from "components/Select";
import { SearchForm } from "components/FullTable";

const FormItem = Form.Item;

@observer
export default class SearchFormCliente extends Component {
  render() {
    const {
      dataSource,
      onUpdateDataSource,
      showCidade = true,
      showPerfil = true,
      showCanal = true,
      showCategoria = false,
      showSedeVitoria = false,
      filterFields = {},
      filterParser = {},
      children,
    } = this.props;

    const { categorias, canais, perfis } = dataSource.state;

    const inputProps = {
      readOnly: dataSource.isLoading,
      disabled: dataSource.isLoading,
    };

    return (
      <SearchForm
        layout="inline"
        dataSource={dataSource}
        onUpdateDataSource={onUpdateDataSource}
        filterFields={{
          cidade: "text",
          status: "options",
          categoria: "options",
          cod_perfil: "options",
          dias_sem_comprar: "options",
          cod_canal: "options",
          ...filterFields,
        }}
        filterParser={{
          cidade: filter => ({ ...filter, value: `%${filter.value}%`, op: "LIKE" }),
          ...filterParser,
        }}>
        {({ form }) => (
          <Fragment>
            {false && (
              <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} label="Filtrar por Cidade" style={{ display: showCidade ? "inline-block" : "none" }}>
                {form.getFieldDecorator("cidade")(
                  <Input {...inputProps} placeholder="Todas as Cidades" style={{ width: 160 }} />
                )}
              </FormItem>
            )}
            <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} style={{ width: 80 }} label="Bloqueado?">
              {form.getFieldDecorator("status")(
                <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Todos" style={{ width: 80 }}>
                  <Option value="ATIVO">Não</Option>
                  <Option value="INATIVO">Sim</Option>
                </Select>
              )}
            </FormItem>
            {showCategoria && _size(categorias) > 0 && (
              <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} style={{ width: 110 }} label="Categoria">
                {form.getFieldDecorator("categoria")(
                  <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Todas" style={{ width: 96 }}>
                    {Object.keys(categorias).map(c => (
                      <Option key={`categoria_${c}`} value={c}>
                        {categorias[c]}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}
            {showCanal && _size(canais) > 0 && (
              <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} style={{ width: 165 }} label="Canal">
                {form.getFieldDecorator("cod_canal")(
                  <Select
                    {...inputProps}
                    onChange={() => {
                      if (showPerfil) form.setFieldsValue({ cod_perfil: undefined });
                    }}
                    defaultActiveFirstOption={false}
                    placeholder="Todos"
                    style={{ width: 164 }}>
                    {Object.keys(canais).map(c => (
                      <Option key={`canal_${c}`} value={c}>
                        {canais[c]}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}
            {showSedeVitoria && (
              <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} style={{ width: 210 }} label="Compra Premiada">
                {form.getFieldDecorator("participa_sedevitoria")(
                  <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Todos" style={{ width: 210 }}>
                    <Option value="A">Todos</Option>
                    <Option value="S">Participando</Option>
                    <Option value="N">Não Participando</Option>
                    <Option value="C">Carta impressa e não enviada</Option>
                    <Option value="E">Carta entregue ao cliente</Option>
                    <Option value="R">Necessário Re-envio da carta</Option>
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} style={{ width: 170 }} label="Não compraram:">
              {form.getFieldDecorator("dias_sem_comprar")(
                <Select {...inputProps} defaultActiveFirstOption={true} placeholder="Sem Filtro" style={{ width: 170 }}>
                  <Option value="30">nos ultimos 30 dias</Option>
                  <Option value="60">nos ultimos 60 dias</Option>
                  <Option value="90">nos ultimos 90 dias</Option>
                  <Option value="120">nos ultimos 120 dias</Option>
                  <Option value="150">nos ultimos 150 diass</Option>
                  <Option value="180">nos ultimos 180 dias</Option>
                </Select>
              )}
            </FormItem>
            {showPerfil && _size(perfis) > 0 && (
              <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} style={{ width: 173 }} label="Perfil">
                {form.getFieldDecorator("cod_perfil")(
                  <Select {...inputProps} defaultActiveFirstOption={false} placeholder="Todos" style={{ width: 172 }}>
                    {perfis
                      .filter(p => !form.getFieldValue("cod_canal") || form.getFieldValue("cod_canal") === p.canal)
                      .map(p => (
                        <Option key={`perfil_${p.cod_perfil}`} value={p.cod_perfil}>
                          {p.descricao_perfil}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            )}
            {!!children && typeof children === "function" && children({ form, inputProps })}
            <FormItem label="Pesquisar" labelCol={{ span: 24 }} wrapperCol={{ span: 5 }} >
              <Button type="danger" htmlType="submit" icon="search" />
            </FormItem>
          </Fragment>
        )}
      </SearchForm>
    );
  }
}
