import React, { Component } from "react";
import { Modal, Row, Col, Form, Input, Button, Spin, message } from "antd";
import { observer } from "mobx-react";

import { fetchApi, fetchPostApi } from "actions";
import { getData } from "@util";
import withStore from "withStore";

import { v } from "components/FormBuilder";
import PageHeader, { BackButton } from "components/PageHeader";
import { MaskedInput } from "antd-mask-input";
import ChooserTransp from "components/pedidos/ChooserTransp";
import InputSearch from "components/InputSearch";

const FormItem = Form.Item;

@observer
class UsersCargoAddScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTransportadora: false,
      transportadora: null,
    };
  }
  async componentDidMount() {
    if (this.props.match.params.edit !== "add") {
      await this.fetchBase();
    } else if (this.props.store.isTransportador) {
      this.setState({ isTransportadora: true, transportadora: this.props.store?.transportadorData?.codigo })
    }
  }
  async fetchBase() {
    this.props.store.isLoading = true;

    try {
      const { data: response } = await fetchApi("/transportadores/usercargo/edit/" + this.props.match.params.edit);
      if (!!response.success) {
        const { transportadora, nome, cpf, telefone, email, login, senha, isTransportadora } = response.data;
        this.props.form.setFieldsValue({
          transportadora,
          nome,
          cpf,
          telefone,
          email,
          login,
          senha,
        });
        this.setState({ isTransportadora, transportadora })
      } else {
        throw new Error("Não foi possível obter dados atualizados para este formulário");
      }
    } catch (err) {
      Modal.error({
        title: "Atenção",
        content: "Não foi possível obter dados atualizados para este formulário",
        onOk: () => this.props.history.replace("/transportadores/users-cargo"),
      });
    } finally {
      this.props.store.isLoading = false;
    }
  }

  async saveUserCargo(data) {
    const { store, history, match } = this.props;

    const errorMessages = {
      1: "Falha ao cadastrar com esse login. Tente outro.",
      2: "Falha ao cadastrar o motorista nessa transportadora.",
      10: "Não foi possível registrar o motorista neste momento. Tente novamente mais tarde",
      11: "Transportadora não pode ficar vazia!",
    };

    store.isLoading = true;

    try {
      console.log('this.state.transportadora -> ', this.state.transportadora);
      if (this.state.transportadora === null || this.state.transportadora === '') {
        console.log('OXE -> ',);
        throw new Error(errorMessages[11]);
      }
      if (match.params.edit !== "add") {
        data = {
          ...data,
          codigo: match.params.edit,
        };
      }
      data = {
        ...data,
        transportadora: this.state.transportadora,
      };
      const { data: response } = await fetchPostApi(
        "/transportadores/usercargo/" + (match.params.edit === "add" ? "save" : "update"),
        data
      );
      const msgSaveOrEdit =
        match.params.edit === "add" ? "Motorista registrado com sucesso" : "Motorista atualizado com sucesso";
      if (!!response.success) {
        Modal.success({
          title: msgSaveOrEdit,
          onOk() {
            history.replace("/transportadores/users-cargo");
          },
          keyboard: false,
        });

        store.isLoading = false;
      } else {
        throw new Error(errorMessages[10]);
      }
    } catch (err) {
      console.log('err -> ', err);
      Modal.error({
        title: "Atenção",
        content: err.message,
      });

      store.isLoading = false;
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, formData) => {
      if (!err) {
        this.saveUserCargo(Object.keys(formData).reduce((acc, k) => ({ ...acc, [k]: formData[k] || "" }), {}));
      }
    });
  };
  openChooser = (modal = "cliente", onChoose = null) => {
    const _onChoose = (...args) => {
      if (!!onChoose && typeof onChoose === "function") {
        onChoose(...args);
      }
    };

    switch (modal) {
      case "transp":
        this._choosers.transp.open(value => {
          if (!!value) {
            this.setState({ transportadora: value.codigo_transportadora })
            _onChoose(value);
          }
        });
        break;
      default:
        console.warn("Chooser modal not found");
        break;
    }
  };
  loadTransp = async () => {
    const { store } = this.props;
    try {
      store.isLoading = true;

      const { data: response } = await fetchPostApi('/pedidos/transportadoras/0000/desp/' + this.state.transportadora);

      if (!!response.success) {
        if (response.data.length <= 0) {
          throw new Error();
        } else {
          this.setState({ transportadora: response.data.codigo_transportadora })
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      message.error('Não foi possível encontrar essa transportadora!');
      this.setState({ transportadora: null })
    } finally {
      store.isLoading = false;
    }
  }
  render() {
    const { isLoading } = this.props.store;
    const { getFieldDecorator } = this.props.form;
    const { match } = this.props;

    const inputProps = {
      readOnly: isLoading,
      autoComplete: "off",
    };

    console.log('isLoading -> ', isLoading);

    const isAdd = match.params.edit === "add";

    return (
      <div className="cadastro-motorista-screen">
        <PageHeader
          title={isAdd ? "Novo Motorista" : `Editar motorista #${match.params.edit}`}
          headerLeft={<BackButton onClick={() => this.props.history.goBack()} />}
        />
        <Spin spinning={!!isLoading}>
          <Form
            autoComplete="off"
            layout="vertical"
            hideRequiredMark={false}
            onSubmit={this.handleSubmit}
            className="cadastro-cliente--form">
            <div>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label="Transportadora">
                    <InputSearch
                      disabled={this.state.isTransportadora}
                      value={this.state.transportadora}
                      onChange={({ target: { value } }) => this.setState({ transportadora: value })}
                      onBlur={this.loadTransp}
                      onSearch={value => this.openChooser("transp")}
                      maxLength="4"
                    />
                  </FormItem>
                </Col>
                <Col span={9}>
                  <FormItem label="Nome">
                    {getFieldDecorator("nome", { rules: [v.required] })(
                      <Input placeholder="Nome" maxLength={100} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="CPF">
                    {getFieldDecorator("cpf", { rules: [v.required, v.cpf] })(
                      <Input placeholder="CPF" maxLength={11} onKeyPress={this.handleKeyPress} {...inputProps} />
                      // <MaskedInput mask="111.111.111-11" onKeyPress={this.handleKeyPress} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label="Telefone">
                    {getFieldDecorator("telefone", { rules: [v.required] })(
                      <MaskedInput mask="(11) 11111-1111" {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="E-mail">
                    {getFieldDecorator("email", { rules: [v.email, v.required] })(
                      <Input placeholder="E-mail" maxLength={100} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={3}>
                  <FormItem label="Login">
                    {getFieldDecorator("login", { rules: [v.required] })(
                      <Input placeholder="Login" maxLength={30} {...inputProps} />
                    )}
                  </FormItem>
                </Col>
                <Col span={3}>
                  <FormItem label="Senha">
                    {getFieldDecorator("senha", {
                      rules: [v.required],
                    })(<Input placeholder="Senha" maxLength={30} {...inputProps} />)}
                  </FormItem>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 6, marginBottom: 16 }}>
              <Button
                style={{ marginTop: 9 }}
                loading={isLoading}
                type="primary"
                htmlType="submit"
                className="cadastro-motorista--button">
                {isAdd ? "Enviar" : "Editar"} cadastro
              </Button>
            </div>
          </Form>
          <ChooserTransp
            ref={_ref => (this._choosers["transp"] = _ref)}
            loading={this.props.store.isLoading}
            pedido={this.pedido}
            tipo="desp"
          />
        </Spin>
      </div>
    );
  }
  _choosers = {};
}

export default Form.create()(withStore(UsersCargoAddScreen));
